import React from 'react';
import { Router } from '@reach/router';
import { PageProps } from 'gatsby';
import { CompanyPreviewRoot } from 'modules/companyPreview/CompanyPreviewRoot';

const Company: React.FC<PageProps> = () => {
  return (
    <>
      <Router basepath="/:lang/company">
        <CompanyPreviewRoot path=":companyHandle/*" />
      </Router>
    </>
  );
};

export default Company;
